import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { rewards_actions } from '../../../../store/actions/rewards';
import defaultrecompensa from '../../../../assets/img/recompensas/recompensas.png';

let user = JSON.parse(localStorage.getItem('user'));

// const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];


var artgroups = new Array();
artgroups[1] = 'FIGLOSNTE2';
artgroups[2] = 'FIGLOSNTE 8';
artgroups[3] = 'FIGLOSNTE 13';
artgroups[4] = 'FIGLOSNTE 16';
artgroups[5] = 'FIGLOSNTE 23';
artgroups[6] = 'FIGLOSNTE 25';
artgroups[7] = 'FIGLOSNTE 26';
artgroups[8] = 'FIGLOSNTE 32';
artgroups[9] = 'FIGLOSNTE 33';
artgroups[10] = 'FIGLOSNTE 47';
artgroups[11] = 'FIGLOSNTE 53';
artgroups[12] = 'FIGLOSNTE 55';
artgroups[13] = 'FIGLOSNTE 56';
artgroups[14] = 'FIGLOSTASE';
artgroups[15] = 'FIGLOSNTSA';
artgroups[16] = 'JUBICOM 16';
artgroups[17] = 'JUBICOM 27';
artgroups[18] = 'FIGLOSNTE 27';
artgroups[19] = 'DAFI';
artgroups[20] = 'CORPORATIVO';
artgroups[21] = 'FIGLOSNSTA';
artgroups[22] = 'FIGLOSNTE47';



const AgregarRecompensa = ({ onCloseSubMenu }) => {
    const [fileUrl, setFileUrl] = useState(defaultrecompensa);

    const [groupslist, setGroupslist] = useState([]);

    const dispatch = useDispatch();

    const validate_add = Yup.object().shape({
        title: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(20, 'Debe ser menor a 20 caracteres')
            .required('El campo es requerido'),
        description: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .required('El campo es requerido'),
        file: Yup.mixed()
            .required("El campo es requerido")
            .test(
                "fileSize",
                `El archivo es demasiado grande`,
                value => {
                    return value && value.size <= 2621440
                }
            ),
        price: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
            .positive('Debe ser un numero positivo')
            .required("El campo es requerido"),
        discount: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
    });
    const validateData = values => {
        let errors = {};
        
        const day = new Date(Date.now());
        let formatTime;
        formatTime = moment(day).format("YYYY-MM-DD[T]HH:mm");

        if (formatTime > values.promotionend) {
            errors.promotionend = 'Debe ser mayor a fecha actual';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            file: '',
            price: 0,
            discount: 0,
            promotionend: '',
            initialamount: -1,
            fideicomiso: 0
        },
        validationSchema: validate_add,
        validate: validateData,

        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async (values) => {

        const imageBase64 = await convertBase64(values.file);
        delete values.file;
        values.image = imageBase64;

        let unixstartdate = convertDatetoUnix(values.promotionend);
        values.promotionend = unixstartdate;

        await rewards_actions.create_reward(values);
        onCloseSubMenu(false);
        dispatch(rewards_actions.get_all_rewards());
    }

    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    const convertDatetoUnix = (datetime) => {
        let utctime = datetime;
        let date = moment(utctime);
        console.log(date);
        let dateComponent = date.format('YYYY-MM-DD HH:mm:ss');

        let unixtime = moment(dateComponent, 'YYYY.MM.DD HH:mm:ss').unix()
        return unixtime;
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    useEffect(() => {
        console.debug('open new reward');
        var filteredgroups = [];
        if (user.ismanager && user.managerlist.length) {
            for (const llist in user.managerlist) {
                //console.debug(user.managerlist[llist].name);
                for (const lgroup in artgroups) {
                    //console.debug(' - ' + artgroups[lgroup]);
                    if (artgroups[lgroup] == user.managerlist[llist].name) {
                        //filteredgroups[lgroup] = artgroups[lgroup];
                        filteredgroups.push({fideicomisonum: lgroup, fideicomisoname: artgroups[lgroup]});
                    }
                }
            }
            
        } else {
            for (const lgroup in artgroups) {
                filteredgroups.push({fideicomisonum: lgroup, fideicomisoname: artgroups[lgroup]});
            }
        }

        setGroupslist(filteredgroups);
           

    }, []);

   /*useEffect(() => {
        //console.debug(artgroups);
        groupslist.map((value, index) => {
            console.debug(value);
            console.debug(index);
        });
    }, [groupslist]);*/

    return (
        <form onSubmit={formik.handleSubmit} className="formularios">
            <div className="flex cursor-pointer items-center text-md mb-4 relative group">
                <div className="w-3/5">
                    <img className="h-20 w-20 rounded-full overflow-hidden" src={fileUrl} alt=""></img>
                </div>
                <div className="flex items-center text-md relative group ml-1">
                    <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Imagen para la recompensa</label>
                    <p className="text-3xl  group-hover:text-opacity-50">+</p>
                    <input onChange={e => {
                        processImage(e);
                        formik.setFieldValue('file', e.target.files[0])
                    }} name="file" type="file" className="absolute top-0 left-0 opacity-0" />
                    {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                </div>
            </div>
            <p className="text-red-600 text-sm mb-6">{formik.errors.file ? <div>{formik.errors.file}</div> : null}</p>
            <div className="campo">
                <label className="text-md" htmlFor="">Titulo de la recompensa</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="title" value={formik.values.title} placeholder="" />
                <p className="text-red-600 text-sm">{formik.errors.title ? <div>{formik.errors.title}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Descripción de la recompensa</label>
                <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="description" placeholder="" value={formik.values.description} />
                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Precio de la recompensa</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="price" value={formik.values.price} placeholder="" />
                <p className="text-red-600 text-sm">{formik.errors.price ? <div>{formik.errors.price}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Descuento de la recompensa</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="discount" value={formik.values.discount} placeholder="" />
                <p className="text-red-600 text-sm">{formik.errors.discount ? <div>{formik.errors.discount}</div> : null}</p>
            </div>
            <div className="campo">
                <label htmlFor="">Fecha de expiracion</label>
                <input className="rounded-md" onChange={formik.handleChange} type="datetime-local" name="promotionend" value={formik.values.promotionend} />
                <p className="text-red-600 text-sm">{formik.errors.promotionend ? <div>{formik.errors.promotionend}</div> : null}</p>
            </div>
            <div className="campo">
                <label htmlFor="">Cantidad disponible</label>
                <input className="rounded-md" onChange={formik.handleChange} type="number" name="initialamount" value={formik.values.initialamount} />
                <p className="text-red-600 text-sm">{formik.errors.initialamount ? <div>{formik.errors.initialamount}</div> : null}</p>
            </div>
            <div className="campo">
                <label htmlFor="">Fideicomiso</label>
                <select onChange={formik.handleChange} className="custom-select" name="fideicomiso" id="fideicomiso">
                    <option value={0}>TODOS</option>
                    {/*<option value={2}>{groupslist[2]}</option>*/}
                    {/*groupslist.length ? <option value={3}>{groupslist[3]}</option>:<></>*/}
                    {groupslist.length ? groupslist.map(element => {
                        console.debug(element);
                        return <option value={element.fideicomisonum}>{element.fideicomisoname}</option>
                    }):<></>}
                    {/*<option value={1}>--FIGLOSNTE 2</option>
                    <option value={2}>FIGLOSNTE 8</option>
                    <option value={3}>FIGLOSNTE 13</option>
                    <option value={4}>FIGLOSNTE 16</option>
                    <option value={5}>FIGLOSNTE 23</option>
                    <option value={6}>FIGLOSNTE 25</option>
                    <option value={7}>FIGLOSNTE 26</option>
                    <option value={8}>FIGLOSNTE 32</option>
                    <option value={9}>FIGLOSNTE 33</option>
                    <option value={10}>FIGLOSNTE 47</option>
                    <option value={11}>FIGLOSNTE 53</option>
                    <option value={12}>FIGLOSNTE 55</option>
                    <option value={13}>FIGLOSNTE 56</option>
                    <option value={14}>FIGLOSTASE</option>
                    <option value={15}>FIGLOSNTSA</option>
                    <option value={16}>JUBICOM 16</option>
                    <option value={17}>JUBICOM 27</option>
                    <option value={18}>FIGLOSNTE 27</option>
                    <option value={19}>DAFI</option>
                    <option value={20}>CORPORATIVO</option>
                    <option value={21}>FIGLOSNSTA</option>
                    <option value={22}>FIGLOSNTE47</option>*/}
                </select>
                <p className="text-red-600 text-sm">{formik.errors.initialamount ? <div>{formik.errors.initialamount}</div> : null}</p>
            </div>
            <button type="submit" className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-36 hover:bg-opacity-100">Guardar</button>
        </form>
    );
}

export default AgregarRecompensa;