import Swal from 'sweetalert2'
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';



export const rewards_service = {
    get_all_rewards,
    create_reward,
    edit_reward,
    delete_reward,
    api_get_exchange_list
};


async function get_all_rewards() {

    const request_rewards = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=local_targettpoints_get_articles&moodlewsrestformat=json`);
    
    if (typeof request_rewards.data === 'object') {
        const rewards = request_rewards.data;
        return { rewards }
    }

    const error = request_rewards.data;
    return { error };

}


async function create_reward(reward) {

    console.log(reward);
    let formData = new FormData()
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'local_targettpoints_create_article');
    formData.append('moodlewsrestformat', 'json');
    formData.append('title', reward.title);
    formData.append('description', reward.description);
    formData.append('image', reward.image);
    formData.append('price', reward.price);
    formData.append('discount', reward.discount);
    formData.append('promotionend', reward.promotionend);
    formData.append('initialamount', reward.initialamount);
    formData.append('groupid', reward.fideicomiso);

    const request_rewards = await api_iac.post(`webservice/rest/server.php`, formData);
    
    if (typeof request_rewards.data === 'object') {
        return 1;
    }

    const error = request_rewards.data;
    return { error };

}

async function edit_reward(reward) {

    let formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", 'local_targettpoints_update_article');
    formData.append("moodlewsrestformat", "json");
    formData.append("id", reward.id);
    formData.append("newtitle", reward.title);
    formData.append("newdescription", reward.description);
    formData.append('newimage', reward.image);
    formData.append('newgroupid', reward.groupid);
    formData.append('newprice', reward.baseprice);
    formData.append("newdiscount", reward.discount);
    formData.append('newpromotionend', reward.promotionend);
    formData.append('newidnumber', '');
    formData.append('newamount', reward.initialamount);

    const request_rewards = await api_iac.post(`webservice/rest/server.php`, formData);

    if (typeof request_rewards.data === 'object') {
        return 1;
    }

    const error = request_rewards.data;
    return { error };

}

async function delete_reward(reward) {

    var formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", 'local_targettpoints_delete_article');
    formData.append("moodlewsrestformat", "json");
    formData.append("id", reward.id);

    const request = await api_iac.post(`webservice/rest/server.php`, formData);

    if (typeof request.data === 'object') {
        return 1;
    }

    const error = request.data;
    return { error };
}

async function api_get_exchange_list(timestart, timeend, excludePending, excludeInProcess, excludeDelivered, username, fideicomisonum) {
    var formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", "local_targettpoints_get_exchanges_list");
    formData.append("moodlewsrestformat", "json");
    if (timestart) {
        formData.append("timestart", timestart);
    }

    if (timeend) {
        formData.append("timeend", timeend);
    }

    if (excludePending) {
        formData.append("excludePending", excludePending);
    }

    if (excludeInProcess) {
        formData.append("excludeInProcess", excludeInProcess);
    }

    if (excludeDelivered) {
        formData.append("excludeDelivered", excludeDelivered);
    }

    if (username) {
        formData.append("username", username);
    }

    if (fideicomisonum) {
        formData.append("fideicomisonum", fideicomisonum);
    }

    const request = await api_iac.post(`webservice/rest/server.php`, formData);

    console.debug(request);

    if (typeof request.data === 'object') {
        return request.data;
    }

    return {'error': 'Error desconocido', 'exchanges': []};
}