import { rewards_constants } from '../constants/rewards';


const initialState = {
    rewards: [],
    loading_rewards: null,
    error_rewards: null,

    loading_rewards_create: null,
    rewards_create: null,
    error_rewards_create: null,

    loading_exchange_list: null,
    exchange_list: null,
    error_exchange_list: null
};

export function rewards_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET rewards--------------//
        case rewards_constants.REWARDS_REQUEST:
            return {
                ...state,
                loading_rewards: action.payload,
            };
        case rewards_constants.REWARDS_SUCCESS:
            return {
                ...state,
                loading_rewards: false,
                rewards: action.payload,
                error_rewards: null
            };
        case rewards_constants.REWARDS_FAILURE:
            return {
                ...state,
                loading_rewards: false,
                error_rewards: action.payload
            };
        //-------------CREATE rewards-----------------
        case rewards_constants.REWARDS_CREATE_REQUEST:
            return {
                ...state,
                loading_rewards_create: action.payload,
            };
        case rewards_constants.REWARDS_CREATE_SUCCESS:
            return {
                ...state,
                loading_rewards_create: false,
                rewards_create: action.payload,
                error_rewards_create: null
            };
        case rewards_constants.REWARDS_CREATE_FAILURE:
            return {
                ...state,
                loading_rewards_create: false,
                error_rewards_create: action.payload
            };
        // ------------- EDIT COHORTE ----------
        case rewards_constants.REWARDS_EDIT_REQUEST:
            return {
                ...state,
                loading_rewards_edit: action.payload,
            };
        case rewards_constants.REWARDS_EDIT_SUCCESS:
            return {
                ...state,
                loading_rewards_edit: false,
                rewards_edit: action.payload,
                error_rewards_edit: null
            };
        case rewards_constants.REWARDS_EDIT_FAILURE:
            return {
                ...state,
                rewards_edit: 0,
                loading_rewards_edit: false,
                error_rewards_edit: action.payload
            };

        //------------DELETE rewards--------------//
        case rewards_constants.REWARDS_DELETE_REQUEST:
            return {
                ...state,
                loading_rewards_delete: action.payload,
                reward_delete: null,
                error_reward_delete: null
            };
        case rewards_constants.REWARDS_DELETE_SUCCESS:
            return {
                ...state,
                loading_rewards_delete: false,
                reward_delete: action.payload,
                error_reward_delete: false
            };
        case rewards_constants.REWARDS_DELETE_FAILURE:
            return {
                ...state,
                loading_rewards_delete: false,
                reward_delete: 0,
                error_reward_delete: action.payload
            };
        case rewards_constants.EXCHANGES_LIST_REQUEST:
            return {
                ...state,
                loading_exchange_list: true,
                exchange_list: null,
                error_exchange_list: null
            };
        case rewards_constants.EXCHANGES_LIST_SUCCESS:
            return {
                ...state,
                loading_exchange_list: false,
                exchange_list: action.payload,
                error_rewards: null
            };
        case rewards_constants.EXCHANGES_LIST_FAILURE:
            return {
                ...state,
                loading_exchange_list: false,
                exchange_list: null,
                error_rewards: action.payload
            }
        default:
            return state
    }
}