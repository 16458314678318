import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { rewards_actions } from '../../../../store/actions/rewards';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const Recompensa = ({ onCloseSubMenu, reward, clickSubMenu }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [OpenDropdown, setOpenDropdown] = useState(false);

    const dispatch = useDispatch();

    const onClickEdit = () => {
        setShowMenu(true)
        clickSubMenu()
    }

    const artgroups = new Array();
    artgroups[0] = 'FIGLOSNTE2';
    artgroups[2] = 'FIGLOSNTE 8';
    artgroups[3] = 'FIGLOSNTE 13';
    artgroups[4] = 'FIGLOSNTE 16';
    artgroups[5] = 'FIGLOSNTE 23';
    artgroups[6] = 'FIGLOSNTE 25';
    artgroups[7] = 'FIGLOSNTE 26';
    artgroups[8] = 'FIGLOSNTE 32';
    artgroups[9] = 'FIGLOSNTE 33';
    artgroups[10] = 'FIGLOSNTE 47';
    artgroups[11] = 'FIGLOSNTE 53';
    artgroups[12] = 'FIGLOSNTE 55';
    artgroups[13] = 'FIGLOSNTE 56';
    artgroups[14] = 'FIGLOSTASE';
    artgroups[15] = 'FIGLOSNTSA';
    artgroups[16] = 'JUBICOM 16';
    artgroups[17] = 'JUBICOM 27';
    artgroups[18] = 'FIGLOSNTE 27';
    artgroups[19] = 'DAFI';
    artgroups[20] = 'CORPORATIVO';
    artgroups[21] = 'FIGLOSNSTA';
    artgroups[22] = 'FIGLOSNTE47';
    
        /*const artgrupos = [
            {id: 0, name: 'FIGLOSNTE2'},
            {id: 2, name: 'FIGLOSNTE 8'},
            {id: 3, name: 'FIGLOSNTE 13'},
            {id: 4, name: 'FIGLOSNTE 16'},
            {id: 5, name: 'FIGLOSNTE 23'},
            {id: 6, name: 'FIGLOSNTE 25'},
            {id: 7, name: 'FIGLOSNTE 26'},
            {id: 8, name: 'FIGLOSNTE 32'},
            {id: 9, name: 'FIGLOSNTE 33'},
            {id: 10, name: 'FIGLOSNTE 47'},
            {id: 11, name: 'FIGLOSNTE 53'},
            {id: 12, name: 'FIGLOSNTE 55'},
            {id: 13, name: 'FIGLOSNTE 56'},
            {id: 14, name: 'FIGLOSTASE'},
            {id: 15, name: 'FIGLOSNTSA'},
            {id: 16, name: 'JUBICOM 16'},
            {id: 17, name: 'JUBICOM 27'},
            {id: 18, name: 'FIGLOSNTE 27'},
            {id: 19, name: 'DAFI'},
            {id: 20, name: 'CORPORATIVO'},
            {id: 21, name: 'FIGLOSNSTA'},
            {id: 22, name: 'FIGLOSNTE47'},
        ];*/

    const onClickDelete = async () => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que deseas eliminar la recompensa?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed === true) {
            await rewards_actions.delete_reward(reward);
            dispatch(rewards_actions.get_all_rewards());
        }
    }

    const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }

    return (
        <div className="flex justify-center border rounded-md mb-2 relative bg-indigo-100" style={{ border: '1px solid #797974' }}>
            <div className="absolute top-0 right-2 z-10">
                <span onMouseLeave={() => {document.addEventListener("click", onClickOutsideListener)}} 
                    onClick={() => setOpenDropdown(!OpenDropdown)}>
                    {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                    <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                </span>
                {
                    OpenDropdown
                        ?
                        <div className="bg-white absolute top-5 right-2 p-2">
                            <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => onClickEdit()}>Editar</p>
                            <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={() => onClickDelete()}>Eliminar</p>

                        </div>
                        :
                        ''
                }
            </div>
            <div className="w-1/3"> <img src={`${reward.image}`} alt="" style={{ width: '90%', height: '90%', objectFit: 'cover' }} /> </div>
            <div className="ml-1 w-4/5 p-2">
                <div>
                    <h4 className="text-xl">{reward.title}</h4>
                    <p className="text-sm">{reward.description}</p>
                    {reward.groupid>0?<p className="text-md my-1">S&oacute;lo para {artgroups[reward.groupid]}</p>:''}
                    <p><strong>Iniciales:</strong><span>&nbsp;{reward.initialamount > -1?reward.initialamount:"ilimitados"}</span></p>
                    <p><strong>Reclamos:</strong><span>&nbsp;{reward.totalredeems}</span></p>
                    <p><strong>Disponibles actualmente:</strong><span>&nbsp;{reward.initialamount > 0?reward.initialamount-reward.totalredeems:"ilimitados"}</span></p>
                </div>
            </div>
        </div>
    );
}

export default Recompensa;