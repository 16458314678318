export const rewards_constants = {
    REWARDS_REQUEST: 'REWARDS_REQUEST',
    REWARDS_SUCCESS: 'REWARDS_SUCCESS',
    REWARDS_FAILURE: 'REWARDS_FAILURE',

    CREATE_REWARDS_REQUEST: 'CREATE_REWARDS_REQUEST',
    CREATE_REWARDS_SUCCESS: 'CREATE_REWARDS_SUCCESS',
    CREATE_REWARDS_FAILURE: 'CREATE_REWARDS_FAILURE',

    EDIT_REWARDS_REQUEST: 'EDIT_REWARDS_REQUEST',
    EDIT_REWARDS_SUCCESS: 'EDIT_REWARDS_SUCCESS',
    EDIT_REWARDS_FAILURE: 'EDIT_REWARDS_FAILURE',

    DELETE_REWARDS_REQUEST: 'DELETE_REWARDS_REQUEST',
    DELETE_REWARDS_SUCCESS: 'DELETE_REWARDS_SUCCESS',
    DELETE_REWARDS_FAILURE: 'DELETE_REWARDS_FAILURE',
    
    EXCHANGES_LIST_REQUEST: 'REWARDS_REQUEST',
    EXCHANGES_LIST_SUCCESS: 'REWARDS_SUCCESS',
    EXCHANGES_LIST_FAILURE: 'REWARDS_FAILURE',
}