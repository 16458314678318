import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import agregar from '../../../assets/img/user/agregar.png'
import exportar from '../../../assets/img/insignias/exportar.png'
import buscar_usuario from '../../../assets/img/user/buscar_usuario.svg'
import Header from './Header';
import SubMenu from './SubMenu/SubMenu';

import AgregarRecompensa from './Recompensa/AgregarRecompensa';
import Recompensa from './Recompensa/Recompensa'
import EditarRecompensa from './Recompensa/EditarRecompensa';
import Reportereclamos from './Recompensa/ReporteReclamos';

let user = JSON.parse(localStorage.getItem('user'));

const Recompensas = ({ onClose }) => {
    const [busquedaRes, guardarBusquedadRes] = useState([]);
    const rewards = useSelector(state => state.rewards);

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        reward: {}
    });

    var artgroups = new Array();
    artgroups[1] = 'FIGLOSNTE2';
    artgroups[2] = 'FIGLOSNTE 8';
    artgroups[3] = 'FIGLOSNTE 13';
    artgroups[4] = 'FIGLOSNTE 16';
    artgroups[5] = 'FIGLOSNTE 23';
    artgroups[6] = 'FIGLOSNTE 25';
    artgroups[7] = 'FIGLOSNTE 26';
    artgroups[8] = 'FIGLOSNTE 32';
    artgroups[9] = 'FIGLOSNTE 33';
    artgroups[10] = 'FIGLOSNTE 47';
    artgroups[11] = 'FIGLOSNTE 53';
    artgroups[12] = 'FIGLOSNTE 55';
    artgroups[13] = 'FIGLOSNTE 56';
    artgroups[14] = 'FIGLOSTASE';
    artgroups[15] = 'FIGLOSNTSA';
    artgroups[16] = 'JUBICOM 16';
    artgroups[17] = 'JUBICOM 27';
    artgroups[18] = 'FIGLOSNTE 27';
    artgroups[19] = 'DAFI';
    artgroups[20] = 'CORPORATIVO';
    artgroups[21] = 'FIGLOSNSTA';
    artgroups[22] = 'FIGLOSNTE47';

//    const [openReport, setOpenReport] = useState({});

    const clickSubMenu = (type, open_param, reward) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            reward: reward
        })
    }

    const handleChange = e => {

        if (e.target.value != '' && e.target.value != undefined) {
            const resultado = rewards.rewards.filter(item => {
                if (item) {
                    return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
                }
            });
            guardarBusquedadRes(resultado);
        } else {
            guardarBusquedadRes(rewards.rewards);
        }
    }

    useEffect(() => {
        var filteredgroups = [];
        if (user.ismanager && user.managerlist.length) {
            for (const llist in user.managerlist) {
                //console.debug(user.managerlist[llist].name);
                for (const lgroup in artgroups) {
                    //console.debug(' - ' + artgroups[lgroup]);
                    if (artgroups[lgroup] == user.managerlist[llist].name) {
                        filteredgroups[lgroup] = artgroups[lgroup];
                    }
                }
            }
            artgroups = filteredgroups;
        }
        /*var selectedartgroup, filteredrewards = [];

        if (user.ismanager && user.managerlist.length) {
            console.debug('*** Is manager');
            console.debug(user.managerlist[0].name);
            console.debug(artgroups[3]);
            for (const lgroup in artgroups) {
                if (artgroups[lgroup]===user.managerlist[0].name) {
                    selectedartgroup = lgroup;
                    break;
                }
            }
            console.debug(selectedartgroup);
            for(const lreward in rewards.rewards) {
                console.debug(rewards.rewards[lreward].groupid);
                if (rewards.rewards[lreward].groupid===0 || rewards.rewards[lreward].groupid===selectedartgroup) {
                    filteredrewards.push(rewards.rewards[lreward]);
                }
            }
            guardarBusquedadRes(filteredrewards);
        } else {*/
            guardarBusquedadRes(rewards.rewards)
       // }
    }, [rewards])

    return (
        <div>
            <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                <Header title="Recompensas" onClose={onClose} />

                <div className="flex flex-row flex-wrap justify-end mb-2">
                    <div onClick={() => clickSubMenu('Agregar', !openSubMenu.open)} className=" m-2 flex items-center cursor-pointer">Crear recompensa <p className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</p> </div>
                </div>

                <div className="flex flex-row flex-wrap justify-end mb-2">
                    <div onClick={() => clickSubMenu('Reporte', !openSubMenu.open, {})} className="m2 flex items-center cursor-pointer">Reporte de reclamos</div>
                </div>

                {/* <div className="mb-4">
                    <div className="bg-white h-12 flex p-1">
                        <input autoComplete="off" onChange={handleChange} className="bg-blue-400 w-11/12 p-1 mr-2" type="text" />
                        <img className="" src={buscar_usuario} alt="buscar_usuario" />
                    </div>

                    <p className=" text-sm text-right">Filtros</p>
                </div> */}

                <div>
                    {busquedaRes.length > 0 ?
                        <div className=" mt-5">
                            <p className="text-xs mb-1">Resultados: {busquedaRes.length}</p>
                            {busquedaRes.map(reward => (
                                <Recompensa onCloseSubMenu={setOpenSubMenu} key={reward.id} reward={reward}
                                    clickSubMenu={() => clickSubMenu('Editar', !openSubMenu.open, reward)} />))}
                        </div>
                        : <p className="">No tienes recompensas creadas</p>}
                    {openSubMenu.open ?
                        <SubMenu>
                            {openSubMenu.type === 'Reporte' ?
                            <div className="min-w-36 bg-gray-100 w-4/5 h-80 pl-3 rounded-xl scroll">
                                <h3 className="text-center py-2 text-lg text-bold">Reporte de reclamos de recompensas</h3>
                                <Reportereclamos onCloseSubMenu={() => {console.debug('Close report pressed'); clickSubMenu('', false, []);}} />
                            </div>
                            :
                            <div>
                                <div className="min-w-full submenu">
                                    <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                        <div className=" m-2 flex justify-between items-center text-2xl">
                                            <p className="mr-1">{openSubMenu.type}</p>
                                            <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 absolute" style={{top: '-1px', marginLeft: '-0.1rem'}} />
                                            </div>
                                        </div>
                                        {openSubMenu.type === 'Agregar' ?
                                            <AgregarRecompensa onCloseSubMenu={setOpenSubMenu} /> :
                                            <EditarRecompensa onCloseSubMenu={setOpenSubMenu} reward={openSubMenu.reward} />
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                        </SubMenu>
                        : null}
                </div>
            </div>
        </div>

    );
}


export default Recompensas;