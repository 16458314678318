import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { rewards_actions } from '../../../../store/actions/rewards';
import SubMenu from '../SubMenu/SubMenu';

let user = JSON.parse(localStorage.getItem('user'));


var artgroups = new Array();
artgroups[1] = 'FIGLOSNTE2';
artgroups[2] = 'FIGLOSNTE 8';
artgroups[3] = 'FIGLOSNTE 13';
artgroups[4] = 'FIGLOSNTE 16';
artgroups[5] = 'FIGLOSNTE 23';
artgroups[6] = 'FIGLOSNTE 25';
artgroups[7] = 'FIGLOSNTE 26';
artgroups[8] = 'FIGLOSNTE 32';
artgroups[9] = 'FIGLOSNTE 33';
artgroups[10] = 'FIGLOSNTE 47';
artgroups[11] = 'FIGLOSNTE 53';
artgroups[12] = 'FIGLOSNTE 55';
artgroups[13] = 'FIGLOSNTE 56';
artgroups[14] = 'FIGLOSTASE';
artgroups[15] = 'FIGLOSNTSA';
artgroups[16] = 'JUBICOM 16';
artgroups[17] = 'JUBICOM 27';
artgroups[18] = 'FIGLOSNTE 27';
artgroups[19] = 'DAFI';
artgroups[20] = 'CORPORATIVO';
artgroups[21] = 'FIGLOSNSTA';
artgroups[22] = 'FIGLOSNTE47';



const EditarRecompensa = ({ reward, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const [promotionend, setPromotionEnd] = useState();
    const [fileUrl, setFileUrl] = useState(`${reward.image}`);

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        course: {}
    });
    
    const [groupslist, setGroupslist] = useState([]);

    const validate_edit = Yup.object().shape({
        title: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(20, 'Debe ser menor a 20 caracteres')
            .required('El campo es requerido'),
        description: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .required('El campo es requerido'),
        baseprice: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
            .positive('Debe ser un numero positivo')
            .required("El campo es requerido"),
        discount: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
    });

    const formik = useFormik({
        initialValues: { ...reward },
        validationSchema: validate_edit,
        onSubmit: values => {

            handleSubmit(values)
        },
    });
    const handleSubmit = async values => {

        if (values.file) {
            const imageBase64 = await convertBase64(values.file);
            values.image = imageBase64;
            delete values.file;
        }

        delete values.currentprice;

        values.promotionend = promotionend;

        if (typeof values.promotionend === 'string') {
            let unixstartdate = convertDatetoUnix(values.promotionend);
            values.promotionend = unixstartdate;
        }

        parseInt(values.baseprice);
        parseInt(values.discount);

        parseInt(values.initialamount);

        await rewards_actions.edit_reward(values);
        onCloseSubMenu(false);
        dispatch(rewards_actions.get_all_rewards());
    }

    function handleChangeDate(event) {
        let val = event.target.value;

        if (!val.length) {
            val = 0;
        }
        setPromotionEnd(val)
    }

    const unixTime = reward.promotionend;
    let formatTime = 0;

    const convertUnixtoDate = () => {
        if (unixTime <= 0) return;

        const milliseconds = unixTime * 1000;
        const dateObject = new Date(milliseconds);
        //const subDate = dateObject.toLocaleString("en-US").substring(0, 17);

        formatTime = moment(dateObject).format("YYYY-MM-DD[T]HH:mm:ss");

        setPromotionEnd(formatTime);
        formik.setFieldValue('promotionend', formatTime);
    }


    useEffect(() => {
        var filteredgroups = [];
        if (user.ismanager && user.managerlist.length) {
            for (const llist in user.managerlist) {
                //console.debug(user.managerlist[llist].name);
                for (const lgroup in artgroups) {
                    //console.debug(' - ' + artgroups[lgroup]);
                    if (artgroups[lgroup] == user.managerlist[llist].name) {
                        //filteredgroups[lgroup] = artgroups[lgroup];
                        filteredgroups.push({fideicomisonum: lgroup, fideicomisoname: artgroups[lgroup]});
                    }
                }
            }
            
        } else {
            for (const lgroup in artgroups) {
                filteredgroups.push({fideicomisonum: lgroup, fideicomisoname: artgroups[lgroup]});
            }
        }

        setGroupslist(filteredgroups);

        convertUnixtoDate();
    }, [])


    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    const convertDatetoUnix = (datetime) => {
        let utctime = datetime;
        let date = moment(utctime);
        let dateComponent = date.format('YYYY-MM-DD HH:mm:ss');
        let unixtime = moment(dateComponent, 'YYYY.MM.DD HH:mm:ss').unix()
        return unixtime;
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div>
            <div>
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                <form onSubmit={formik.handleSubmit} className="formularios">
                    <div className="flex cursor-pointer items-center text-xl mb-4 relative group">
                        <div className="w-2/5">
                            <img className="h-20 w-20 rounded-full overflow-hidden" src={fileUrl} alt=""></img>
                        </div>
                        <div className="flex items-center text-xl relative group ml-1">
                            <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Imagen para la recompensa</label>
                            <p className="text-3xl  group-hover:text-opacity-50">+</p>
                            <input onChange={e => {
                                processImage(e);
                                formik.setFieldValue('file', e.target.files[0])
                            }} name="file" type="file" className="absolute top-0 left-0 opacity-0" />
                            {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                        </div>
                    </div>
                    <p className="text-red-600 text-sm mb-6">{formik.errors.file ? <div>{formik.errors.file}</div> : null}</p>
                    <div className="campo">
                        <label className="text-xl" htmlFor="">Titulo de la recompensa</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="text" name="title" value={formik.values.title} placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.title ? <div>{formik.errors.title}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="text-xl" htmlFor="">Descripción de la recompensa</label>
                        <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="description" placeholder="" value={formik.values.description} />
                        <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="text-xl" htmlFor="">Precio de la recompensa</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="number" name="baseprice" value={formik.values.baseprice} placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.baseprice ? <div>{formik.errors.baseprice}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="text-xl" htmlFor="">Descuento de la recompensa</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="number" name="discount" value={formik.values.discount} placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.discount ? <div>{formik.errors.discount}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Fecha de expiracion</label>
                        <input className="rounded-md" onChange={handleChangeDate} type="datetime-local" name="promotionend" value={promotionend} />
                        <p className="text-red-600 text-sm">{formik.errors.promotionend ? <div>{formik.errors.promotionend}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Cantidad iniciales</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="number" name="initialamount" value={formik.values.initialamount} />
                        <p className="text-red-600 text-sm">{formik.errors.initialamount ? <div>{formik.errors.initialamount}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Cantidad reclamados</label>
                        <input className="rounded-md" disabled type="number" name="initialamount" value={formik.values.totalredeems} />
                    </div>
                    <div className="campo">
                        <label htmlFor="">Cantidad disponible</label>
                        <input className="rounded-md" disabled type="number" name="initialamount" value={formik.values.initialamount > -1?formik.values.initialamount - formik.values.totalredeems:-1} />
                    </div>        
                    <div className="campo">
                        <label htmlFor="">Fideicomiso</label>
                        <select onChange={formik.handleChange} className="custom-select" name="groupid" id="groupid" value={formik.values.groupid}>
                            <option value={0}>TODOS</option>
                            {groupslist.length ? groupslist.map(element => {
                                console.debug(element);
                                return <option value={element.fideicomisonum}>{element.fideicomisoname}</option>
                            }):<></>}
                            {/*<option value={1}>FIGLOSNTE 2</option>
                            <option value={2}>FIGLOSNTE 8</option>
                            <option value={3}>FIGLOSNTE 13</option>
                            <option value={4}>FIGLOSNTE 16</option>
                            <option value={5}>FIGLOSNTE 23</option>
                            <option value={6}>FIGLOSNTE 25</option>
                            <option value={7}>FIGLOSNTE 26</option>
                            <option value={8}>FIGLOSNTE 32</option>
                            <option value={9}>FIGLOSNTE 33</option>
                            <option value={10}>FIGLOSNTE 47</option>
                            <option value={11}>FIGLOSNTE 53</option>
                            <option value={12}>FIGLOSNTE 55</option>
                            <option value={13}>FIGLOSNTE 56</option>
                            <option value={14}>FIGLOSTASE</option>
                            <option value={15}>FIGLOSNTSA</option>
                            <option value={16}>JUBICOM 16</option>
                            <option value={17}>JUBICOM 27</option>
                            <option value={18}>FIGLOSNTE 27</option>
                            <option value={19}>DAFI</option>
                            <option value={20}>CORPORATIVO</option>
                            <option value={21}>FIGLOSNSTA</option>
                            <option value={22}>FIGLOSNTE47</option>*/}
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.initialamount ? <div>{formik.errors.initialamount}</div> : null}</p>
                    </div>
                    <button type="submit" className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 hover:bg-opacity-100 px-4 py-2 w-36">Guardar</button>
                </form>
            </div>
            {openSubMenu.open ?
                <SubMenu>
                    <div>
                        <div className="min-w-full  submenu">
                            <div className="w-1/4 bg-blue-600 h-80 rounded-xl scroll">
                                <div className=" flex justify-between items-center text-2xl p-4">
                                    <p className="mr-1">{openSubMenu.type}</p>
                                    <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '-1px', marginLeft: '1.3rem' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </SubMenu>
                : null}
        </div>
    );
}

export default EditarRecompensa;