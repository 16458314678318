import React, { useCallback, useEffect, useState } from 'react';
import { rewards_service } from '../../../../store/services/rewards';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const ReporteReclamos = ({onClose}) => {

    const [loading, setLoading] = useState(false);
    const [exchanges, setExchanges] = useState([]);

    const refreshExchangesList = (a_timestart, a_timeend, a_excludePending, a_excludeInProcess, a_excludeDelivered, a_username, a_fideicomisonum) => {
        rewards_service.api_get_exchange_list(a_timestart, a_timeend, a_excludePending, a_excludeInProcess, a_excludeDelivered, a_username, a_fideicomisonum).then((res) => {
            console.debug(res);
            if (res.error!=='') {
                Swal.fire('Recompensas', res.error, 'error');
                setLoading(false);
            } else {
                setExchanges(res.exchanges);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        setLoading(true);

        refreshExchangesList(0, 0, 0, 0, 0, '', 0);

    }, []);

    function getReadableDate(timestamp) {
        var dateObj = new Date(timestamp * 1000);

        return dateObj.toLocaleDateString() + ' - ' + dateObj.toLocaleTimeString();
    }

    
    const onClickRwFilter = e => {
        var userVal = document.getElementById('rewardUserInput').value;

        if (userVal!='') {
            refreshExchangesList(0, 0, 0, 0, 0, userVal, 0);
        }

    }

    return (
        <div>
            {loading ? <div>Cargando...</div>
            :
            <div>
                
                <div>
                    <div><label className="ml-2">Buscar por usuario:</label><input id="rewardUserInput" type="text" className="m-2 rounded p-2 font-bold border-2 border-solid border-blue-input" /><button onClick={onClickRwFilter} className="text-white bg-blue-btnbg width-200p rounded px-2 py-3 cursor-pointer mx-4 my-0 font-bold">Buscar reclamos</button></div>
                </div>
                {exchanges.length ? 
                    <table className="w-full border-collapse my-8">
                        <thead className="bg-tabletop text-black font-bold text-center">
                            <tr>
                                <th className="border border-solid border-white-25">Recompensa</th>
                                <th className="border border-solid border-white-25">Usuario</th>
                                <th className="border border-solid border-white-25">Nombre</th>
                                <th className="border border-solid border-white-25">Fecha y hora de reclamo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exchanges.map( exchange => (
                                <tr>
                                    <th className="border border-solid border-white-25">{exchange.articlename}</th>
                                    <th className="border border-solid border-white-25">{exchange.username}</th>
                                    <th className="border border-solid border-white-25">{exchange.firstname}&nbsp;{exchange.lastname}</th>
                                    <th className="border border-solid border-white-25">{getReadableDate(exchange.creationtime)}</th>
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                   
                 : <p>No se encontraron resultados...</p>}
            </div>
            }
             {/*<div className="border border-solid border-gray-700 rounded-md mb-2 relative bg-indigo-100 p-1">
                        <p><b>Articulo:</b> {exchange.articlename}</p>
                        <p className="py-2">{exchange.articledesc}</p>
                        <p><b>Solicitado por:</b> {exchange.firstname}&nbsp;{exchange.lastname}&nbsp;({exchange.username})</p>
                        <p className="pb-1"><b>Solicitado el:</b> {getReadableDate(exchange.creationtime)}</p>
                        {exchange.deliverytime?<p><b>Entregado el:</b> {getReadableDate(exchange.deliverytime)}</p>:<p>Pendiente de entregar...</p>}
                    </div>*/}
            {/*div className="absolute" style={{width: "48px", height: "48px", top: "1%", right: "20%"}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '-1px', marginLeft: '1.3rem' }} />
            </div>*/}
        </div>
    );
}

export default ReporteReclamos;